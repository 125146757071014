import {updateDates} from '../../../../commons/actions/dates'
import {loadCalendarEvents, loadEventsPage, loadFeaturedEvent} from '../actions/events'

export const datesMiddleware = store => next => action => {
  switch (action.type) {
    case loadFeaturedEvent.fulfilled.toString():
    case loadCalendarEvents.fulfilled.toString():
    case loadEventsPage.fulfilled.toString(): {
      if (
        (loadEventsPage.fulfilled.match(action) ||
          loadCalendarEvents.fulfilled.match(action) ||
          loadFeaturedEvent.fulfilled.match(action)) &&
        action.payload
      ) {
        store.dispatch(updateDates(action.payload.dates))
      }
      break
    }
    default:
      break
  }
  next(action)
}
