import {Dispatch} from 'redux'
import {getEventListPageUrl} from '../services/navigation'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'
import {getLanguage, getLocale, getTimezone, isEditor, isMobile, isPreview, isSite} from '../utils/wix-code-api'

export const SET_BASE_ENVIRONMENT = 'SET_BASE_ENVIRONMENT'
export const SET_FORM_FACTOR = 'SET_FORM_FACTOR'

export const setBaseEnvironment =
  () =>
  async (dispatch: Dispatch, getState: GetCommonState, {wixCodeApi}: CommonStoreExtraArgs) => {
    const state = getState()

    const homePageUrl = wixCodeApi.location.baseUrl
    const eventListPageUrl = await getEventListPageUrl(wixCodeApi)

    dispatch({
      type: SET_BASE_ENVIRONMENT,
      payload: {
        seo: wixCodeApi.seo.isInSEO(),
        locale: getLocale(wixCodeApi) || state.siteSettings.locale,
        language: getLanguage(wixCodeApi),
        timezone: getTimezone(wixCodeApi),
        mobile: isMobile(wixCodeApi),
        editor: isEditor(wixCodeApi),
        preview: isPreview(wixCodeApi),
        site: isSite(wixCodeApi),
        eventListPageUrl: eventListPageUrl?.url ?? homePageUrl,
        eventListPageRelativeUrl: eventListPageUrl?.relativeUrl,
        homePageUrl,
      },
    })
  }

export const setFormFactor = (formFactor: string) => ({
  type: SET_FORM_FACTOR,
  payload: formFactor === 'Mobile',
})
